.image-slider {
  margin-bottom: $spacing;

  &__meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__nav {
    display: flex;
    align-items: center;
  }

  .swiper {
    &-slide {
      width: auto;
    }
    &-pagination {
      position: static;
      text-align: left;
      @include font-semibold;
      @include font-italic;
      @include headline;

      &-current {

      }

      &-total {
        @include font-light;
        @include footnote;
      }
    }
    &-button-prev,
    &-button-next {
      position: static;
      color: var(--color-black);
      margin: 1rem;

      &:after {
        @include body;
        height: 26px;
        width: 26px;
      }

      &:hover {
        opacity: .4;
      }
    }
    &-button-prev {
      &:after {
        content: url('data:image/svg+xml; utf8, <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.9596 25.0404C12.3501 25.431 12.9833 25.431 13.3738 25.0404C13.7643 24.6499 13.7643 24.0167 13.3738 23.6262L3.41413 13.6666H24.3334C24.8857 13.6666 25.3334 13.2189 25.3334 12.6666C25.3334 12.1143 24.8857 11.6666 24.3334 11.6666H3.4143L13.3738 1.70711C13.7643 1.31658 13.7643 0.683419 13.3738 0.292895C12.9833 -0.0976312 12.3501 -0.0976312 11.9596 0.292893L0.294246 11.9582C0.135271 12.1166 0.0293072 12.3282 0.00528025 12.564C0.00177701 12.598 0 12.6322 0 12.6667C0 12.7064 0.00235968 12.7458 0.00700135 12.7848C0.0252322 12.9396 0.0788026 13.0835 0.159423 13.2084C0.197618 13.2676 0.242261 13.3231 0.292893 13.3738L11.9596 25.0404Z" fill="currentColor"/> </svg> ');
        width: 20px;
        height: 20px;

        @include respond-to("m") {
          width: 26px;
          height: 26px;
        }
      }
    }
    &-button-next {
      &:after {
        content: url('data:image/svg+xml; utf8, <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13.374 0.292893C12.9835 -0.0976312 12.3503 -0.0976309 11.9598 0.292893C11.5692 0.683418 11.5692 1.31658 11.9598 1.70711L21.9194 11.6667H1C0.447715 11.6667 0 12.1145 0 12.6667C0 13.219 0.447715 13.6667 1 13.6667H21.9192L11.9598 23.6262C11.5692 24.0167 11.5692 24.6499 11.9598 25.0404C12.3503 25.431 12.9835 25.431 13.374 25.0404L25.0406 13.3738C25.0942 13.3203 25.141 13.2613 25.1806 13.1982C25.2447 13.0962 25.2909 12.9818 25.3148 12.8594C25.3272 12.7964 25.3335 12.7318 25.3335 12.6667C25.3335 12.589 25.3245 12.5122 25.307 12.4378C25.2895 12.3632 25.2637 12.2917 25.2305 12.2245C25.1826 12.1273 25.1187 12.0376 25.0406 11.9596L13.374 0.292893Z" fill="currentColor"/></svg> ');
        width: 20px;
        height: 20px;

        @include respond-to("m") {
          width: 26px;
          height: 26px;
        }
      }
    }
  }
}
