.text {
  @include headline;

  &__expand {
    display: block;
    margin: $spacing-xl 0;
  }

  .more {
    margin-top: $spacing-l;
  }
}
