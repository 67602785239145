.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $spacing;

  &--lgap {
    grid-gap: $spacing-xxl $spacing;
  }

  @include respond-to('s') {
    &--3 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include respond-to('m') {
    grid-template-columns: repeat(2, 1fr);

    &--3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
