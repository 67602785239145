.container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;

  &--large {
    width: 100%;
    max-width: 1680px;
    margin: 0 auto;
  }

  /*@include respond-to('xl') {
    max-width: 1680px;
    &--large {
      max-width: 1920px;
    }
  }*/
}

.container--grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 0 $spacing-m;
  overflow: hidden;

  &.container--large {
    padding: 0;
  }

  & > * {
    grid-column: 1 / 13;
  }

  .hero {
    grid-column: 1/12;
  }

  @include respond-to('s') {
    .hero {
      grid-column: 3/11;
    }

    .text, .timeline {
      grid-column: 3/11;
    }

    .media {
      grid-column: 2/12;
    }

    .media--full {
      grid-column: 1 / 13;
    }
  }
}

.container--grid-large {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 0 $spacing-m;

  & > * {
    grid-column: 2 / 13;
  }
}
