.media-grid {
  display: grid;
  grid-gap: $spacing-l;
  grid-row-gap: 60px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  margin: 30px 0 60px;

  picture {

    &, img {
      max-height: 720px;
    }

    &:nth-of-type(1) {
      grid-column: 1 / 11;
      grid-row: 1 / 2;

      @include respond-to("m") {
        grid-column: 1 / 8;
      }
    }
    &:nth-of-type(2) {
      grid-column: 3 / 13;
      grid-row: 2 / 3;
    }
    &:nth-of-type(3) {
      grid-column: 1 / 10;
      grid-row: 3 / 4;

      @include respond-to("m") {
        grid-row: 3 / 5;
        grid-column: 1 / 6;
      }
    }
    &:nth-of-type(4) {
      grid-column: 2 / 12;
      grid-row: 4 / 6;

      @include respond-to("m") {
        grid-column: 7 / 13;
      }
    }
    &:nth-of-type(5) {
      grid-column: 1 / 11;
      grid-row: 6 / 7;
    }
    &:nth-of-type(6) {
      grid-column: 3 / 13;
      grid-row: 7 / 8;

      @include respond-to("m") {
        grid-column: 6 / 13;
      }
    }
    &:nth-of-type(7) {
      grid-column: 2 / 12;
      grid-row: 9 / 10;

      @include respond-to("m") {
        grid-column: 1 / 7;
        grid-row: 9 / 11;
      }
    }
    &:nth-of-type(8) {
      grid-column: 4 / 13;
      grid-row: 10 / 12;

      @include respond-to("m") {
        grid-column: 8 / 13;
      }
    }
    &:nth-of-type(9) {
      grid-column: 3 / 13;
      grid-row: 12 / 13;
    }
    &:nth-of-type(10) {
      grid-column: 1 / 11;
      grid-row: 13 / 14;

      @include respond-to("m") {
        grid-column: 1 / 8;
      }
    }

    &:nth-of-type(even) {
      img {
        margin-left: auto;
      }
    }
  }

  @include respond-to("m") {
    grid-gap: $spacing-xxl;
    grid-row-gap: 90px;
    margin: 60px 0;
  }

  @include respond-to("l") {
    grid-row-gap: 120px;
  }

  /*@include respond-to('xl') {
    picture {

      &, img {
        max-height: 960px;
      }
    }
  }*/
}
