.reference {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-l $spacing-xl;
  border: 1px solid var(--color-grey);
  min-height: 120px;

  &--plain {
    border: none;
    opacity: .5;
  }
}
