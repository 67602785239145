.list-teaser {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $spacing;
  text-decoration: none;

  & + & {
    margin-top: $spacing-xxxl;
  }

  &__headline {
    padding: $spacing-m 0;
    @include headline;
    @include font-semibold;

    small {
      @include font-italic;
      @include font-light;
      @include headline;
    }
  }

  &__tags {
    margin-top: $spacing-m;
  }

  &__teaser {
    @include callout;
  }

  &__link {
    display: block;
    margin-top: $spacing;
  }

  @include respond-to("m") {
    grid-template-columns: 1fr 1fr;
    grid-gap: $spacing-xxxl;

    & + & {
      margin-top: $spacing-xxxl * 2;
    }

    &__content {
      align-self: center;
    }

    &__link {
      margin-top: $spacing-xl;
    }

    &__tags {
      margin-top: $spacing-l;
    }

    &:nth-of-type(even) {
      .list-teaser__media {
        order: 1;
      }
    }
  }
}
