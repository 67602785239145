.hero-slider {
  overflow: hidden;
  color: var(--color-white);

  &__slide {
    display: grid;
    grid-template-columns: repeat(12,1fr);
  }

  &__media {
    grid-column: 1/13;
    grid-row: 1/1;
    height: 72vh;
    overflow: hidden;
    position: relative;
    z-index: 2;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.2);
      z-index: 1;
    }

    picture,img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    grid-column: 1/13;
    grid-row: 1/1;
    align-self: center;
    justify-self: center;
    z-index: 3;
    width: 100%;
  }

  .hero {
    padding: 0;
  }

  @include respond-to('m') {
    &__media {
      height: 100vh;
    }
  }
}
