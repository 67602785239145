.grid-teaser {
  text-decoration: none;

  &__headline {
    padding: $spacing-m 0;
    @include headline;
    @include font-semibold;

    small {
      @include font-italic;
      @include font-light;
      @include headline;
    }
  }

  &__text {
    @include callout;
    max-width: 80ch;
    padding-right: $spacing-l;
  }

  &__link {
    display: block;
    margin-top: $spacing;
  }

  a:hover & {
    &__headline {
      opacity: .4;
    }
  }

  @include respond-to("m") {
    &__text {
      padding-right: $spacing-xxl;
    }
  }
}
