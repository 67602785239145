.btn {
  display: inline-block;
  border: 1px solid var(--color-black);
  background-color: var(--color-black);
  border-radius: 94px;
  color: var(--color-white);
  padding: $spacing-xs $spacing-xl;
  text-decoration: none;
  @include footnote;
  @include font-semibold;
  transition: opacity .2s;

  &:hover {
    opacity: .4;
  }
}

.link {
  display: inline-flex !important;
  align-items: center;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  @include footnote;
  @include font-bold;
  transition: opacity .2s;

  &:after {
    display: block;
    margin-left: 1em;
    height: 14px;
    width: 14px;
    content: url('data:image/svg+xml; utf8, <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.54011 0.292893C7.14959 -0.0976311 6.51643 -0.0976311 6.1259 0.292893C5.73538 0.683418 5.73538 1.31658 6.1259 1.70711L10.252 5.83325H1C0.447715 5.83325 0 6.28097 0 6.83325C0 7.38554 0.447715 7.83325 1 7.83325H10.2522L6.1259 11.9596C5.73538 12.3501 5.73538 12.9833 6.1259 13.3738C6.51643 13.7643 7.14959 13.7643 7.54011 13.3738L13.3605 7.55337C13.5493 7.37147 13.6667 7.11607 13.6667 6.83325C13.6667 6.54846 13.5476 6.29147 13.3566 6.10934L7.54011 0.292893Z" fill="currentColor"/></svg>');
  }

  &:hover {
    opacity: .4;
  }
}

button {
  color: inherit;
}
