/* Variables */

/* Colors */
$black: #000;
$white: #fff;

$gray-1: #707070;
$gray-2: #c0c0c0;
$gray-3: #f0f0f0;

/* Breakpoints */
$breakpoints: (
  'xs': 480px,
  's': 640px,
  'm': 960px,
  'l': 1280px,
  'xl': 1680px
);
/* Z-Indexes */
$z-indexes: (
  'modal': 5000,
  'dropdown': 4000,
  'default': 1,
  'below': -1
);

/* Spacing */
$spacing: 16px;
$spacing-xs: 4px;
$spacing-s: 8px;
$spacing-m: 16px;
$spacing-l: 24px;
$spacing-xl: 32px;
$spacing-xxl: 48px;
$spacing-xxxl: 80px;

:root {
  --color-main: rgba(252, 235, 79, 1);
  --color-secondary: rgba(252, 235, 79, 1);
  --color-black: rgba(22, 22, 29, 1);
  --color-white: rgba(255, 255, 255, 1);
  --color-grey: rgba(227, 227, 227, 1);
  --font-rendering: subpixel-antialiased;
  --font-light: 300;
  --font-regular: 400;
  --font-semibold: 600;
  --font-bold: 700;
}
