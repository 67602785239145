/* Typography */

$sans: 'Mulish', sans-serif;
$serif: Georgia, serif;
$mono: Menlo, Courier, monospace;

@mixin large-title {
  font-size: 30px;
  font-size: clamp(30px,3.5vw,56px);
  line-height: 1.25;
  @include respond-to('s') {
    font-size: 42px;
    font-size: clamp(30px,3.5vw,56px);
  }
  @include respond-to('m') {
    font-size: 56px;
    font-size: clamp(30px,3.5vw,56px);
  }
}
@mixin title-1 {
  font-size: 20px;
  line-height: 1.25;
  @include respond-to('m') {
    font-size: 30px;
  }
}
@mixin headline {
  font-size: 18px;
  line-height: 1.6;
  @include respond-to('m') {
    font-size: 22px;
  }
}

@mixin body {
  font-size: 16px;
  line-height: 1.6;
  @include respond-to('m') {
    font-size: 20px;
  }
}
@mixin callout {
  font-size: 14px;
  line-height: 1.6;
  @include respond-to('m') {
    font-size: 16px;
  }
}

@mixin footnote {
  font-size: 12px;
  line-height: 1.6;
  @include respond-to('m') {
    font-size: 14px;
  }
}

@mixin navigation {
  font-size: 16px;
  line-height: 1.6;
  @include respond-to('s') {
  }
  @include respond-to('m') {
    font-size: 14px;
  }
  @include respond-to('l') {
  }
  @include respond-to('xl') {
  }
}

@mixin dark-on-light {
  -webkit-font-smoothing: antialiased;
}

.dark-on-light {
  @include dark-on-light;
}

@mixin font-normal {
  font-style: normal;
}

@mixin font-italic {
  font-style: italic;
}

@mixin font-light {
  font-weight: var(--font-light);
}

@mixin font-regular {
  font-weight: var(--font-regular);
}

@mixin font-semibold {
  font-weight: var(--font-semibold);
}

@mixin font-bold {
  font-weight: var(--font-bold);
}

@mixin font-upper {
  text-transform: uppercase;
  letter-spacing: .01em;
}
