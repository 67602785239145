/* Language Switcher */
.language {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;

  &__item {
    display: flex;
    align-items: center;
    &:after {
      content: "";
      width: 1px;
      height: 16px;
      background-color: currentColor;
      margin-left: .5rem;
    }
    & + .language__item {
      margin-left: .5rem;
    }
    &:last-of-type {
      margin-right: 1.5rem;
      &:after {
        content: none;
      }
    }
  }

  &__link {
    display: block;
    padding: .5rem 0;
    @include font-regular;
    @include font-upper;
    color: inherit;
    text-decoration: none;

    &:hover,
    &.active {
      @include font-semibold;
      text-decoration: underline;
    }
  }
}
