.aspect-ratio-box {
  height: 0;
  overflow: hidden;
  padding-top: 62.5%;
  background: transparent;
  position: relative;

  &--169 {
    padding-top: 56.25%;
  }

  &--43 {
    padding-top: 75%;
  }

  &-inside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img, video, svg {
      width: 100%;
      max-width: none;
      height: 100%;
      object-fit: cover;
    }
  }
}
