.timeline {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &__block {
    margin: 0;
    padding: 0;

    & + & {
      margin-top: $spacing-xxxl;
    }
  }

  &__headline {
    margin-bottom: $spacing-m;
    @include title-1;
  }

  &__item {
    @include headline;
    list-style-type: "· ";
    list-style-position: outside;
    margin: 0;
    padding: 0;
  }
}
