.flex {
  display: flex;

  &--overflow {
    white-space: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    & > * {
      flex-shrink: 0;
      opacity: .65;
    }
  }
}
