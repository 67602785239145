.footer {
  background-color: var(--color-black);
  color: var(--color-white);
  padding: 2rem 0;
  // margin-top: $spacing-xxxl * 2;
  margin-top: auto;
  flex-shrink: 0;
  @include footnote;
  @include font-regular;

  &__headline {
    @include font-bold;
    margin-bottom: $spacing-s;
  }

  &__block {
    & + & {
      margin-top: $spacing-l;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @include respond-to("m") {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  nav {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }

  a {
    transition: opacity .2s;

    &:hover {
      opacity: .4;
    }
  }

  @include respond-to("m") {
    &__headline {
      margin-bottom: $spacing-m;
    }

    &__block {
      & + & {
        margin-top: 0;
      }
    }
  }
}
