.section {
  & + & {
    margin-top: $spacing-xl;
  }
  &__header {
    @include title-1;
    margin-bottom: $spacing-xl;
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $spacing-xxl;
  }

  &.margin {
    &-top {
      &--small {
        margin-top: $spacing;
      }
      &--large {
        margin-top: $spacing-xxxl;
      }
    }
  }

  @include respond-to("m") {

    & + & {
      margin-top: $spacing-xxl;
    }

    &.margin {
      &-top {
        &--large {
          margin-top: 1.5*$spacing-xxxl;
        }
      }
    }
  }

  @include respond-to("l") {

    & + & {
      margin-top: $spacing-xxxl;
    }

    &.margin {
      &-top {
        &--large {
          margin-top: 2*$spacing-xxxl;
        }
      }
    }
  }
}
