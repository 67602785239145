@import 'node_modules/font-face-generator/mixin';

@include font-face(
  $fonts: (
    'Mulish': (
      300: (
        normal: 'Mulish-Light',
      ),
      400: (
        normal: 'Mulish-Regular',
        italic: 'Mulish-Italic'
      ),
      600: (
        normal: 'Mulish-SemiBold',
      ),
      700: (
        normal: 'Mulish-Bold',
      )
    )
  ),
  $types: 'ttf',
  $path: '../fonts'
);
