.marquee {
  margin: $spacing-xxl 0;

  .js-marquee > div {
    display: flex;
  }

  @include respond-to('m') {
    margin: $spacing-xxxl 0;
  }
}
