.data {
  display: flex;
  flex-direction: column;
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-gap: $spacing;

  &__item {
    & + & {

    }
  }

  &__label {
    @include footnote;
    @include font-italic;
  }

  &__value {
    @include body;
    @include font-semibold;
    margin: 0;
  }

  @include respond-to("s") {
    // grid-template-columns: 1fr 1fr;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    &__item {
      & + & {
        margin-left: 3rem;
      }
    }
  }

  @include respond-to("m") {
    // grid-template-columns: 1fr 1fr 1fr 1fr;

    &__item {
      & + & {
        margin-left: 6rem;
      }
    }
  }

  @include respond-to("l") {
    &__item {
      & + & {
        margin-left: 9rem;
      }
    }
  }
}
