.hero {
  padding-top: $spacing-xl;
  padding-bottom: $spacing-l;

  &__headline {
    @include large-title;
    @include font-light;
    @include font-italic;

    strong {
      @include font-bold;
      @include font-normal;
    }
  }
  &__subline {
    @include body;
    margin-top: $spacing-xl;
  }

  @include respond-to("m") {
    padding-top: $spacing-xxxl;
    padding-bottom: $spacing-xl;
  }
}
