.chips {
  display: flex;
  flex-wrap: wrap;
  margin: -$spacing-xs;
  padding: 0 0 $spacing-xl;

  & > * {
    margin: $spacing-xs;
  }
}

.chip {
  display: block;
  color: var(--color-black);
  border: 1px solid var(--color-black);
  border-radius: 94px;
  padding: $spacing-xs $spacing-l;
  text-decoration: none;
  cursor: pointer;
  transition: opacity .2s;
  @include callout;

  &:hover, &:active {
    opacity: .4;
  }

  &--filled {
    color: inherit;
    border: transparent;
    background: transparent;

    &:hover, &:active {
      opacity: 1;
      background: #efefef;
    }

    &.is-active {
      background: #efefef;
    }
  }
}
