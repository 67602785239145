/* Base */

*,
*:after,
*:before {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  vertical-align: baseline;
  font-family: $sans;
  @include font-regular;
  font-style: normal;
}

html {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  position: relative;
  background-color: var(--color-white);
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-top: $spacing-xxxl;

  &.landingpage {
    padding-top: 0;
  }
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

a {
  color: inherit;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

.is-hidden {
  display: none;
}
