.markdown {
  p {
    & + p {
      margin-top: 1em;
    }
  }

  * + h1,
  * + h2,
  * + h3,
  * + h4 {
    margin-top: 2em;
  }
}
