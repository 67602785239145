.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.swiper {
  &-slide {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
}

.mtruncate {
  text-overflow:ellipsis;
  overflow:hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.page {
  margin-bottom: $spacing-xxxl * 2;
}
