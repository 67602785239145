.header {
  --foreground: var(--color-white);
  --foreground-s: var(--color-black);
  --background-p: transparent;
  --background-s: var(--color-white);
  background-color: var(--background-p);
  color: var(--foreground);
  position: fixed;
  top: -1px;
  left: 0;
  width: 100%;
  z-index: 500;

  &__bar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }

  &__logo {
    display: block;
    text-decoration: none;

    & svg {
      width: 65px;
      display: block;
    }
  }
  &__menu {
    flex: 1 1 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.2s ease-out;
  }
  &__menu-item {
    padding: 0;
    &:first-child {
      margin-top: $spacing;
    }
    &:last-child {
      margin-bottom: $spacing;
    }
    &--actions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      @include navigation;
    }
    &--action {
      margin-left: auto;
    }
  }
  &__link {
    display: block;
    @include navigation;
    @include font-semibold;
    text-decoration: none;
    padding: $spacing-s 0;
    &:hover, &.active {
      text-decoration: underline;
    }
  }
  &__btn {
    @extend .btn;
    @include navigation;
    background-color: var(--background-s);
    border-color: transparent;
    color: var(--foreground-s);
    margin-top: $spacing-s;
  }
  &__menu-icon {
    width: 40px;
    height: 30px;
    margin-right: 14px;
    position: relative;
    transform: rotate(0deg);
    cursor: pointer;
    border: none;
    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: var(--foreground);
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      &:nth-child(1) {
        top: 3px;
        transform-origin: left center;
      }
      &:nth-child(2) {
        top: 13px;
      }
      &:nth-child(3) {
        top: 23px;
        transform-origin: left center;
      }
    }
  }
  &__menu-btn {
    display: none;

    &:checked {
      & ~ .header__menu {
        max-height: 100vh;
      }
      & ~ .header__menu-icon {
        span {
          &:nth-child(1) {
            transform: rotate(45deg);
            top: 0;
            left: 7px;
          }
          &:nth-child(2) {
            width: 0;
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            top: 28px;
            left: 7px;
          }
        }
      }
    }
  }

  @include respond-to("m") {
    &__bar {
      flex-wrap: nowrap;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
    }
    &__logo {
      grid-column: 1/3;
      & svg {
        width: 87px;
      }
    }
    &__menu {
      grid-column: 3/13;
      max-height: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &__menu-item {

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }

      & + & {
        margin-left: $spacing-xl*2;
      }

      &--actions {
        margin-left: auto !important;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }
    }
    &__link {
      padding: $spacing-l 0;
    }
    &__btn {
      margin-top: 0;
    }
    &__menu-icon {
      display: none;
    }
  }

  .locked & {
    @include respond-until('m') {
      --foreground: var(--color-black);
      --foreground-s: var(--color-white);
      --background-p: var(--color-white);
      --background-s: var(--color-black);
    }
  }

  &.is-plain {
    --foreground: var(--color-black);
    --foreground-s: var(--color-white);
    --background-p: var(--color-white);
    --background-s: var(--color-black);
  }
}
